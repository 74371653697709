import React, { Component } from "react"
import styled from "styled-components"

//GitHubのReleasesで配布されているプラグインはReleaseページへリンクする
class YMM4Plugins extends Component {
  plugins = 
  [
    {
      type: "映像エフェクト",
      date: "2025-04-07",
      name: "反射プラグイン",
      url: "https://github.com/Dolphin-kun/ReflectEffect",
      author: "いるかぁぁ",
      authorUrl: "https://x.com/Dolphin__kun",
      description: "指定範囲内で反射しながら移動アニメーションする映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-04-06",
      name: "モーションブラー",
      url: "https://github.com/tetra-te/SimpleMotionBlurEffect",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "移動・回転・拡大縮小アニメーションにあわせて自動的にブラーをかける映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-04-05",
      name: "BPM同期プラグイン",
      url: "https://benikazura.booth.pm/items/6772352",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "指定したBPMに合わせてエフェクトを繰り返し・切り替えすることのできる映像エフェクトです",
    },
    {
      type: "その他",
      date: "2025-04-04",
      name: "動画出力メッセージプラグイン",
      url: "https://github.com/tetra-te/VideoOutputMessage",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "動画出力ウィンドウにメッセージや起動時間を表示するプラグインです",
    },
    {
      type: "その他",
      date: "2025-04-01",
      name: "<| Falling Icicles |>",
      url: "https://github.com/sinBetaKun/Falling-Icicles",
      author: "sinβ",
      authorUrl: "https://x.com/sinBetaKun",
      description: "エイプリルフール企画として作成された東方二次創作プラグインです",
      isEnabled: false,
    },
    {
      type: "その他",
      date: "2025-03-24",
      name: "YMM-RPC",
      url: "https://github.com/namakemono-san/YMM-RPC",
      author: "なまけもの",
      authorUrl: "https://x.com/nmkmn_moe",
      description: "Discordのユーザープロフィール上にYMM4を表示中であることを表示する機能を提供するプラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2025-03-21",
      name: "アーチ配置プラグイン",
      url: "https://github.com/Dolphin-kun/ArcText",
      author: "いるかぁぁ",
      authorUrl: "https://x.com/Dolphin__kun",
      description: "テキストをアーチ状に配置する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-02-21",
      name: "RhythmAnimation",
      url: "https://github.com/dmmo-com-jp/RhythmAnimation",
      author: "メタロロ",
      authorUrl: "https://x.com/metaroro_H2",
      description: "BPMを指定して拡大縮小・移動・回転させられる映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-02-19",
      name: "RandomAnglePlugin",
      url: "https://github.com/dmmo-com-jp/RandomAnglePlugin",
      author: "メタロロ",
      authorUrl: "https://x.com/metaroro_H2",
      description: "指定間隔毎にランダムに角度を変更する映像エフェクトです",
    },
    {
      type: "音声エフェクト",
      date: "2025-02-17",
      name: "HzWave",
      url: "https://github.com/kotolin-sub/HzWave",
      author: "kotolin",
      authorUrl: "https://x.com/kotolind2a1",
      description: "周波数から波形を生成する音声エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-02-16",
      name: "TextYurashiPlugin",
      url: "https://github.com/dmmo-com-jp/TextYurashiPlugin",
      author: "メタロロ",
      authorUrl: "https://x.com/metaroro_H2",
      description: "揺らしアニメーションするテキスト用の映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2025-02-13",
      name: "比率クリッピング",
      url: "https://github.com/tetra-te/RatioCropEffect",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "比率(%)で指定できるクリッピング可能な映像エフェクトです",
    },
    {
      type: "音声合成",
      date: "2025-02-13",
      name: "YMM4OpenJTalkPlugin",
      url: "https://github.com/InuInu2022/YMM4OpenJTalkPlugin",
      author: "いぬいぬ",
      authorUrl: "https://x.com/InuInuGames",
      description: "YMM4からOpenJtalkの音声を利用できるようにする音声合成プラグインです",
    },
    {
      type: "場面切り替え",
      date: "2025-02-09",
      name: "紅葛式場面切り替えプラグインセットA",
      url: "https://benikazura.booth.pm/items/6574970",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "汎用的に使える計10種類の場面切り替えプラグインです",
    },
    {
      type: "音声合成",
      date: "2025-01-26",
      name: "YMM4 VoiSona Talk",
      url: "https://github.com/InuInu2022/YMM4VoiSonaPlugin",
      author: "いぬいぬ",
      authorUrl: "https://x.com/InuInuGames",
      description: "YMM4からVoiSonaTalkの音声を利用できるようにする音声合成プラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2025-01-26",
      name: "簡易カーニングプラグイン",
      url: "https://github.com/tetra-te/SimpleKerningEffect",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "テキストの位置などを文字ごとに設定できる映像エフェクトです",
    },
    {
      type: "音声合成",
      date: "2025-01-04",
      name: "YMM4-MoreBOUYOMIPlugin",
      url: "https://github.com/taisan11/YMM4-MoreBOUYOMIPlugin",
      author: "taisan",
      authorUrl: "https://x.com/taisan11_",
      description: "YMM4から棒読みちゃんの音声を利用できるようにする音声合成プラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2024-12-29",
      name: "指定時にのみ表示",
      url: "https://github.com/tetra-te/SpecifiedShowEffect",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "プレビュー再生/停止/出力時それぞれで不透明度を切り替えて表示する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-12-28",
      name: "拡大縮小（描画位置）",
      url: "https://github.com/tetra-te/ZoomDrawPositionEffect",
      author: "てとら",
      authorUrl: "https://x.com/tetra_te",
      description: "描画位置を拡大縮小する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-12-07",
      name: "YMM4縁取りTプラグイン",
      url: "https://benikazura.booth.pm/items/6361884",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "exo出力対応の角丸縁取りを行う映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-11-18",
      name: "YMM_ToneCurve",
      url: "https://github.com/mes51/YMM_ToneCurve",
      author: "肉(mes)",
      authorUrl: "https://x.com/mes_deleted_n",
      description: "トーンカーブを使用して色調補正を行う映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-11-17",
      name: "YMM4コマ落ちプラグイン",
      url: "https://benikazura.booth.pm/items/6294022",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "フレームレートを下げ、カクついたアニメーションを可能にする映像エフェクトです",
    },
    {
      type: "図形",
      date: "2024-11-04",
      name: "YMM4スクワークル角丸",
      url: "https://github.com/InuInu2022/YMM4SquirclePlugin",
      author: "いぬいぬ",
      authorUrl: "https://x.com/InuInuGames",
      description: "スクワークル角丸図形を追加する図形プラグインです",
    },
    {
      type: "立ち絵",
      date: "2024-10-31",
      name: "sin型カスタムパーツ立ち絵",
      url: "https://github.com/sinBetaKun/SinTachiePlugin",
      author: "sinβ",
      authorUrl: "https://x.com/sinBetaKun",
      description: "自由度の高い立ち絵の描画・アニメーションが可能な立ち絵プラグインです",
    },
    {
      type: "音声エフェクト",
      date: "2024-10-04",
      name: "音声遅延エフェクト",
      url: "https://github.com/kotolin-sub/YMM4AudioDelayPlugin/",
      author: "kotolin",
      authorUrl: "https://x.com/kotolind2a1",
      description: "左右チャンネル別々に音声を遅れて再生させられる音声エフェクトです",
    },
    {
      type: "立ち絵",
      date: "2024-09-28",
      name: "拡張PSD立ち絵プラグイン",
      url: "https://ymm4plugin.wiki.fc2.com/wiki/PsdTachiePlugin",
      author: "翼",
      authorUrl: "https://x.com/zunwing",
      description: "PSDTool記法を参考にした、より便利にPSDファイルを立ち絵として使用できるようにする立ち絵プラグインです",
    },
    {
      type: "動画出力",
      date: "2024-09-14",
      name: "HighSpeed動画出力プラグイン",
      url: "https://ymm4plugin.wiki.fc2.com/wiki/HighSpeedVideoWriterPlugin",
      author: "翼",
      authorUrl: "https://x.com/zunwing",
      description: "YMM4標準のものに比べ、高速に動画を出力できる動画出力プラグインです",
    },
    {
      type: "動画読み込み",
      date: "2024-09-07",
      name: "HighSpeed動画読み込みプラグイン",
      url: "https://ymm4plugin.wiki.fc2.com/wiki/HighSpeedVideoReaderPlugin",
      author: "翼",
      authorUrl: "https://x.com/zunwing",
      description: "YMM4標準のものに比べ、高速に動画を読み込める動画読み込みプラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2024-08-17",
      name: "YMM4扇クリッピングプラグイン",
      url: "https://benikazura.booth.pm/items/6015501",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "AviUtlスクリプト\"扇クリッピング(R)\"(作 : rikky)の機能の一部を再現した、映像を扇状にクリッピングする映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-08-12",
      name: "YMM_BrokenJpeg",
      url: "https://github.com/mes51/YMM_BrokenJpeg",
      author: "肉(mes)",
      authorUrl: "https://x.com/mes_deleted_n",
      description: "Jpeg圧縮処理を用いて画像を乱す映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-08-08",
      name: "カスタム複製エフェクト",
      url: "https://github.com/sinBetaKun/CustomCloneEffectPlugin",
      author: "sinβ",
      authorUrl: "https://x.com/sinBetaKun",
      description: "映像を任意の複数の位置に複製できる映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-06-30",
      name: "キャラクターモーションプラグイン",
      url: "https://benikazura.booth.pm/items/5870342",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "立ち絵の感情表現に便利なアニメーションを多数収録した映像エフェクトです",
    },
    {
      type: "音声合成",
      date: "2024-06-23",
      name: "YMM4-CapCut",
      url: "https://github.com/taisan11/YMM4-CapCut",
      author: "taisan",
      authorUrl: "https://x.com/taisan_minna",
      description: "YMM4からCapCutの合成音声を利用可能にする音声合成プラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2024-06-05",
      name: "YMM4CustomEasengK",
      url: "https://github.com/kotolin-sub/YMM4CustomEasengK",
      author: "kotolin",
      authorUrl: "https://x.com/kotolind2a1",
      description: "luaスクリプトを使用して座標を操作可能にする映像エフェクトです",
    },
    {
      type: "音声エフェクト",
      date: "2024-06-01",
      name: "YMM4Whisper",
      url: "https://github.com/kotolin-sub/YMM4Whisper",
      author: "kotolin",
      authorUrl: "https://x.com/kotolind2a1",
      description: "音声を囁き声にする音声エフェクトです",
    },
    {
      type: "その他",
      date: "2024-05-24",
      name: "YPEI4",
      url: "https://github.com/RainKasa/YPEI4/",
      author: "RainKasa",
      authorUrl: "https://x.com/amekasa_",
      description: "【プラグイン開発者向け】YMM4のプラグインを簡単に導入できるようにするプロジェクトです",
      isEnabled: false,
    },
    {
      type: "その他",
      date: "2024-05-18",
      name: "YMM4ConsolePlugin",
      url: "https://github.com/kotolin-sub/YMM4_Console_Plugin",
      author: "kotolin",
      authorUrl: "https://x.com/kotolind2a1",
      description: "【プラグイン開発者向け】デバッグウィンドウを追加するプラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2024-05-11",
      name: "ウェーブテキストプラグイン",
      url: "https://benikazura.booth.pm/items/5734530",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "テキストを波状に配置する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-05-05",
      name: "時間差複製エフェクト",
      url: "https://github.com/sinBetaKun/ShiftCloneEffectPlugin/",
      author: "sinβ",
      authorUrl: "https://x.com/sinBetaKun",
      description: "指定した方向に、指定された時間間隔で複製して表示する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-04-29",
      name: "PolarDistortion",
      url: "https://github.com/mes51/YMM_PolarDistortion/",
      author: "肉(mes)",
      authorUrl: "https://x.com/mes_deleted_n",
      description: "直交座標から極座標、極座標から直交座標に変換する映像エフェクトです。巨大な画像に対しても適用することができます。",
    },
    {
      type: "映像エフェクト",
      date: "2024-04-25",
      name: "反復反転",
      url: "https://github.com/Shahulog/FlipEffectPlugin/",
      author: "しゃふろぐ",
      authorUrl: "https://x.com/Shahu_log",
      description: "繰り返し反転させる映像エフェクト",
    },
    {
      type: "映像エフェクト",
      date: "2024-04-22",
      name: "点滅しながら登場退場",
      url: "https://github.com/Shahulog/BlinkInYMM4Plugin",
      author: "しゃふろぐ",
      authorUrl: "https://x.com/Shahu_log",
      description: "点滅しながら登場退場させる映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2024-03-13",
      name: "ビデオチェッカープラグイン",
      url: "https://benikazura.booth.pm/items/5567040",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "映像アイテム(図形アイテムや動画アイテム等)の内部数値を調べることができる映像エフェクトです",
    },
    {
      type: "映像エフェクト, 音声エフェクト, 図形",
      date: "2024-02-16",
      name: "YMMプラグイン集vol.1",
      url: "https://github.com/SomeTea01/YMM4PluginPack_vol1/",
      author: "Sometea_1024",
      authorUrl: "https://x.com/bluesky_1024",
      description: "正多角形、モールス信号生成エフェクト、音声波形生成エフェクト、円運動エフェクト、コマ落ちエフェクト、ポスタリゼーションエフェクトを含むプラグインパックです。",
    },
    {
      type: "図形",
      date: "2024-02-15",
      name: "数値テキストプラグイン",
      url: "https://github.com/Piyo-creator/YMM4PpS/",
      author: "Prer",
      authorUrl: "https://x.com/_p_rer",
      description: "カンマ区切りや小数点の任意の桁数表示に対応した数値表示プラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2024-01-09",
      name: "Volumetric FractalNoise",
      url: "https://github.com/mes51/YMM_VolumetricFractalNoise/",
      author: "肉(mes)",
      authorUrl: "https://x.com/mes_deleted_n",
      description: "3Dのフラクタルノイズを生成する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2023-12-25",
      name: "スクロールプラグイン",
      url: "https://benikazura.booth.pm/items/5370333",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "一定の方向に繰り返しスクロールを行う映像エフェクトを追加する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2023-11-26",
      name: "点滅プラグイン",
      url: "https://github.com/68B09/YMM4Plugins",
      author: "ZZO",
      authorUrl: "https://x.com/MB68B09",
      description: "指定秒数で表示と非表示を繰り返す映像エフェクトです",
    },
    {
      type: "音声エフェクト",
      date: "2023-11-24",
      name: "YukkuriMovieMaker4SoVitsSvcFork",
      url: "https://github.com/34j/YukkuriMovieMaker4SoVitsSvcFork",
      author: "34j",
      authorUrl: "https://github.com/34j",
      description: "so-vits-svc-forkを使用してボイスチェンジャーを適用する音声エフェクトです",
    },
    {
      type: "図形",
      date: "2023-11-20",
      name: "レターボックスプラグイン",
      url: "https://benikazura.booth.pm/items/5269715",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "レターボックス（画面上下や左右の黒帯）図形を追加する図形プラグインです",
    },
    {
      type: "映像エフェクト",
      date: "2023-10-24",
      name: "描画変換プラグイン",
      url: "https://benikazura.booth.pm/items/5199154",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "拡大率、回転角、不透明度、左右反転をエフェクトを適用したタイミングで処理する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2023-10-11",
      name: "Unmultプラグイン",
      url: "https://github.com/mes51/YMM_Unmult/",
      author: "肉(mes)",
      authorUrl: "https://x.com/mes_deleted_n",
      description: "黒背景素材を透過素材に変換する映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2023-10-09",
      name: "疑似不透明度プラグイン",
      url: "https://benikazura.booth.pm/items/5156386",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "背景に単色化画像を重ねながら不透明度を調整できる映像エフェクトです",
    },
    {
      type: "映像エフェクト",
      date: "2023-10-06",
      name: "グリッチノイズプラグイン",
      url: "https://benikazura.booth.pm/items/5126133",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "グリッチ効果（画面が乱れたような効果）を付与する映像エフェクトです",
    },
    {
      type: "動画読み込み",
      date: "2023-09-12",
      name: "クソでか画像縮小プラグイン",
      url: "https://www.youtube.com/watch?v=ET6aQy0AClA",
      author: "ゆっくりコンピュータサイエンス",
      authorUrl: "https://x.com/yukkuri_cs_",
      description: "1920x1080を超えるサイズの画像を予め縮小した状態で読み込むプラグインです。",
    },
    {
      type: "音声エフェクト",
      isEnabled: false,
      date: "2023-09-03",
      name: "音割れプラグイン",
      url: "https://ux.getuploader.com/benikazura_ymm4plugin/download/1",
      author: "べにかずら",
      authorUrl: "https://x.com/benikazura_X",
      description: "音声の音割れさせる音声エフェクトです",
    },
  ];
  types = [
    "映像エフェクト",
    "音声エフェクト",
    "音声合成",
    "動画出力",
    "動画読み込み",
    "場面切り替え",
    "図形",
    "立ち絵",
    "その他",
  ];
  state = {
    type: "すべて",
    max: 100,
  };
  constructor(props) {
    super(props);
    this.state = {
      type: "すべて",
      max: props.max || 99999,
    };
  }
  
  PluginList = ({type, max})=>
  {  
    return this.plugins
      .slice(0, max)
      .filter((plugin,i)=>type == "すべて" || plugin.type.split(",").map((t)=>t.trim()).includes(type))
      .filter((plugin,i)=>plugin.isEnabled != false)
      .sort((a,b)=>new Date(b.date) - new Date(a.date))
      .map((plugin,i)=>
      {
        return (
          <tr key={i}>
            <td>
              <a href={plugin.url} target="_blank" rel="noopener noreferrer">{plugin.name}</a>
            </td>
            <td>
              <a href={plugin.authorUrl} target="_blank" rel="noopener noreferrer">{plugin.author}</a>
            </td>
            <td>
              {plugin.description}
            </td>
          </tr>)
      });
  };
  FilterLink = (type, isBold)=>
  {
    const count = this.plugins.filter((plugin,i)=>type == "すべて" || plugin.type.split(",").map((t)=>t.trim()).includes(type)).length;
    const text = type + "("+count+")";
    const link = (<a href="#" onClick={(e)=>{e.preventDefault();this.setState({type:type})}}>{text}</a>);
    return isBold ? text : link;
  };
  FilterLinks = (currentType)=>
  {
    //すべて(xxx) | 映像エフェクト(xxx) | 音声エフェクト(xxx) | ...
    //※ xxxは一致するプラグインの数
    return (
      <div className="YMM4PluginsFilters">
        {this.FilterLink("すべて", currentType == "すべて")}
        {this.types.map((type,i)=> (<> | <FilterSpan>{this.FilterLink(type, type == currentType)}</FilterSpan> </>))}
      </div>
    ); 
  }

  render() {
    const {type, max} = this.state;
    return (
      <div className="YMM4Plugins">
        <h2>YMM4プラグイン一覧</h2>
        {this.FilterLinks(type)}
        <div className="YMM4PluginsTable">
          <table>
            <thead>
              <tr>
                <th>名前・配布元</th>
                <th>開発者</th>
                <th>説明</th>
              </tr>
            </thead>
            <tbody>
              {this.PluginList({type, max})}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default YMM4Plugins;

const FilterSpan = styled.span`
  display: inline-block;
`
